import React, { useEffect,useState } from "react"
import {useStaticQuery, Link, graphql} from "gatsby"
import icon from '../images/icon.png'
import sun from '../images/sun.svg'
import moon from '../images/moon.svg'

const HeaderAsset=() =>{
    const data = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `
      )
      
      const win=typeof window !== 'undefined' ? localStorage.getItem('dark'):'';
      const [scrolled,setSrcoll] = useState(false)
      const [theme, setTheme]=useState(win)
      const [ontab, setTab]=useState(true)
      const menuLinks=[
        {name: 'About',link: '/about',},
        {name: 'Articles',link: '/blog',}
      ];
      useEffect(()=>{
        localStorage.setItem('dark', theme)
        document.body.setAttribute('class',theme);
      },[theme])
      
      useEffect(()=>{
        const onCurrentTab=()=>{
          if(ontab){
            let newTheme=localStorage.getItem('dark');
            if(newTheme!==theme){
              document.body.setAttribute('class',newTheme);
              setTheme(newTheme);
              //console.log('curent window changed: '+ontab);
            }
            //console.log('curent window notchanged: '+ontab);
            setTab(false);
          }
        }
        const notCurrentTab=()=>{
          setTab(true);
          //console.log('not curent window : '+ontab)
        }
        window.addEventListener('focus', onCurrentTab)
        window.addEventListener('blur', notCurrentTab)
        return()=>{
          window.removeEventListener('focus', onCurrentTab)
          window.removeEventListener('blur',notCurrentTab)
        }
      },[ontab,theme])

      useEffect(()=>{
        setSrcoll(window.scrollY > 20)
        const navOnScroll = () => {
          setSrcoll(window.scrollY > 20)
        }
        window.addEventListener('scroll', navOnScroll)
        return()=>{window.removeEventListener('scroll', navOnScroll)}
      },[scrolled])
    return(
      <nav className={scrolled ? 'nav scroll' : 'nav'}>
        <div className="nav-container">
          <div className="brand">
            <Link to="/" title={data.site.siteMetadata.title}>
              <img src={icon} className="favicon" alt="secret icon" />
              <span className="text">{data.site.siteMetadata.title}</span>
            </Link>
          </div>
          <div className="links">
            {menuLinks.map(link => (
              <Link key={link.name} to={link.link} activeClassName="active">
                {link.name}
              </Link>
            ))}
            <a href='https://rahul3v.xyz' title="Me" target="_blank" rel="noopener noreferrer">Me</a>
            <div className="mode">
              <button className="dark-switcher" onClick={()=>theme==='dark'?setTheme(''):setTheme('dark')} aria-label="Toggle Dark Mode." title="Toggle Dark Mode">
                {theme==='dark' ? (
                  <img src={sun} className="theme-icon" alt="Light Mode" />
                ) : (
                  <img src={moon} className="theme-icon" alt="Dark Mode" />
                )}
              </button>
            </div>
          </div>
        </div>
      </nav>
    )
}
export default HeaderAsset;