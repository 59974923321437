import React from "react"
import { Helmet } from "react-helmet"
import {useStaticQuery, graphql} from "gatsby"
import logo from '../images/favicon.png'
const Head=({title})=>{
  const data=useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return(
        //<Helmet title={data.site.siteMetadata.title}/>
        <Helmet>
            <title>{`${title} | ${data.site.siteMetadata.title}`}</title>
            <link rel="shortcut icon" type="image/png" href={logo} />
        </Helmet>
    )
}
export default Head;