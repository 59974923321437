import React from 'react'
import Helmet from 'react-helmet'
import config from '../SiteConfig'

const SEO=(props)=>{
  const { postNode, postPath, postSEO } = props
  let title
  let description
  let image = ''
  let postURL
  let siteTitle = config.siteTitle

  if (postSEO) {
    const postMeta = postNode.frontmatter
    title = postMeta.title
    description = postMeta.description ? postMeta.description : postNode.excerpt
    image = '/thumbnails/'+ (postMeta.thumbnail ? postMeta.thumbnail : '')
    postURL = config.siteUrl+'/'+postPath
  } else {
    title = props.title?props.title +' | '+ config.siteTitle:config.siteTitle
    description = config.siteDescription
    image = config.siteLogo
  }

  image = config.siteUrl+image
  const blogURL = config.siteUrl+'/'+config.pathPrefix
  const schemaOrgJSON = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: blogURL,
      name: title,
      linkedin:config.linkedin,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    },
  ]

  if (postSEO) {
    schemaOrgJSON.push(
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': postURL,
              name: title,
              image,
            },
          },
        ],
      },
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url: blogURL,
        name: title,
        alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image,
        },
        description,
      }
    )
  }
  
    return (
      <Helmet>
      <meta name="description" content={description} />
      <meta name="image" content={image} />

      <script type="application/ld+json">{JSON.stringify(schemaOrgJSON)}</script>
      <meta property="og:locale" content="en_US"/>
      <meta property="og:url" content={postSEO ? postURL : blogURL} />
      {postSEO && <meta property="og:type" content="article" />}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content={siteTitle}/>

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
    )
  }
export default SEO;