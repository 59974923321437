/*
The main theme of my pages, using all layout components
*/
import React from "react"
import Footer from "./footer"
import Header from "./header"
import Head from "./head"
import SEO from './seo'
//Importing my css
import '../styles/main.scss'
const Layout=(props)=>{
    return(
        <>
            <Head title={props.title}/>
            { props.postSEO ? <SEO postPath={props.postPath} postNode={props.postNode} postSEO/>:<SEO title={props.title}/>}
            <Header/>
            <main id="main-content">
                <div className="container">{props.children}</div>
            </main>
            <Footer/>
        </>
    )
}
export default Layout