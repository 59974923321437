import React from 'react'
import {Link} from "gatsby"
//import GitHubButton from 'react-github-btn'

const PostStyle=(props)=>{
  const edge=props.postEdges;
    return (
     <Link to={`/${edge.node.fields.slug}`}>
        <img src={`/thumbnails/${edge.node.frontmatter.thumbnail}`} alt={edge.node.frontmatter.title}/>
        <h2>{edge.node.frontmatter.title}
        <br/><span>&nbsp; - {edge.node.frontmatter.date}</span>
        </h2>
      </Link>
    )
  }
export default PostStyle;